<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Upload Laporan Perjalanan Dinas </strong><small>Tambah Data</small> 
            <!-- <a class="float-right btn-sm" color="warning" :href="linkPdf" target="_blank">Lihat PDF</a> -->
          </CCardHeader>
          <CCardBody>            
          <CAlert
            color="danger"
            closeButton
            :show.sync="alert1"
          >
            <b>{{ notif }}</b>
          </CAlert>
            <CForm @submit.prevent="updateUpload">
            <CRow>
              <CCol sm="9">
                <CInput
                  v-model="no_surat_tugas"                  
                  label="Nomor Surat Tugas"
                  placeholder="Input Nomor Surat Tugas"
                  readonly
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_surat_tugas"
                  type="date"
                  label="Tanggal Surat Tugas"
                  placeholder="Input Tanggal Surat Tugas"
                  readonly
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="uraian_surat_tugas"
                  label="Uraian Surat Tugas"
                  placeholder="Input Uraian Surat Tugas"
                  readonly
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="tgl_pelaksanaan"
                  type="date"
                  label="Tanggal pelaksanaan"
                  placeholder="Input Tanggal pelaksanaan"
                  readonly
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="jumlah_hari_pelaksanaan"
                  type="number"
                  label="Jumlah Hari Pelaksanaan"
                  placeholder="Input Jumlah Hari Pelaksanaan"
                  readonly
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="dasar_surat_tugas"
                  label="Dasar Surat Tugas"
                  placeholder="Input Dasar Surat Tugas"
                  readonly
                />
              </CCol>
              <CCol sm="12">
                <CTextarea
                  v-model="nama_pelaksana"
                  label="Nama Pelaksana"
                  placeholder="Input Nama Pelaksana"
                  readonly
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="pejabat_ttd"
                  label="Nama Pejabat TTD Surat Tugas"
                  placeholder="Input Nama Pejabat"
                  readonly
                />
              </CCol> 
              <CCol sm="6">
                <div class="form-group">
                <label>Jenis Tujuan Dinas</label>
                  <select v-model="jenis_tujuan" class="form-control" readonly>
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1">Dalam Provinsi</option>
                    <option value="2">Luar Provinsi</option>
                    <option value="3">Luar Negeri</option>
                  </select>
                </div>
              </CCol> 

              <CCol sm="6">
                <div class="form-group">
                  <label>Surat Tugas</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange1($event)" id="inputGroupFile01"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Surat Perjalanan Dinas & Lampirannya *dalam 1 file</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder2" aria-describedby="inputGroupFileAddon02">
                    <label class="custom-file-label" for="inputGroupFile02">{{placeholder2}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Laporan Kegiatan</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange3($event)" id="inputGroupFile03"
                    :placeholder="placeholder3" aria-describedby="inputGroupFileAddon03">
                    <label class="custom-file-label" for="inputGroupFile03">{{placeholder3}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Dasar Surat Tugas (nodin/undangan)*apabila ada</label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange4($event)" id="inputGroupFile04"
                    :placeholder="placeholder4" aria-describedby="inputGroupFileAddon04">
                    <label class="custom-file-label" for="inputGroupFile04">{{placeholder4}}</label>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran Tiket & Nominal *apabila ada</label><br/>                  
                  <div class='row'>
                    <div class='col-7'>
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" @change="onFileChange5($event)" id="inputGroupFile05"
                        :placeholder="placeholder5" aria-describedby="inputGroupFileAddon05">
                        <label class="custom-file-label split_file_label" for="inputGroupFile05">{{placeholder5}}</label>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" v-model="bukti_pembayaran_tiket_nominal"  class="form-control" aria-label="Nomonial (Rupiah)">
                      </div>
                    </div>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran Penginapan & Nominal *apabila ada</label><br/>                  
                  <div class='row'>
                    <div class='col-7'>
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" @change="onFileChange6($event)" id="inputGroupFile06"
                        :placeholder="placeholder6" aria-describedby="inputGroupFileAddon06">
                        <label class="custom-file-label split_file_label" for="inputGroupFile06">{{placeholder6}}</label>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" v-model="bukti_pembayaran_penginapan_nominal"  class="form-control" aria-label="Nomonial (Rupiah)">
                      </div>
                    </div>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran Sewa Kendaraan & Nominal *apabila ada</label><br/>                  
                  <div class='row'>
                    <div class='col-7'>
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" @change="onFileChange7($event)" id="inputGroupFile07"
                        :placeholder="placeholder7" aria-describedby="inputGroupFileAddon07">
                        <label class="custom-file-label split_file_label" for="inputGroupFile07">{{placeholder7}}</label>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" v-model="bukti_pembayaran_sewa_kendaraan_nominal"  class="form-control" aria-label="Nomonial (Rupiah)">
                      </div>
                    </div>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran BBM & Nominal *apabila ada</label><br/>                  
                  <div class='row'>
                    <div class='col-7'>
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" @change="onFileChange8($event)" id="inputGroupFile08"
                        :placeholder="placeholder8" aria-describedby="inputGroupFileAddon08">
                        <label class="custom-file-label split_file_label" for="inputGroupFile08">{{placeholder8}}</label>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" v-model="bukti_pembayaran_bbm_nominal"  class="form-control" aria-label="Nomonial (Rupiah)">
                      </div>
                    </div>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran Transport Lainnya & Nominal *apabila ada</label><br/>                  
                  <div class='row'>
                    <div class='col-7'>
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" @change="onFileChange9($event)" id="inputGroupFile09"
                        :placeholder="placeholder9" aria-describedby="inputGroupFileAddon09">
                        <label class="custom-file-label split_file_label" for="inputGroupFile09">{{placeholder9}}</label>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" v-model="bukti_pembayaran_transport_lainnya_nominal"  class="form-control" aria-label="Nomonial (Rupiah)">
                      </div>
                    </div>
                  </div>
                </div>  
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                  <label>Bukti Pembayaran Taxi *apabila ada</label><br/>                  
                  <div class='row'>
                    <div class='col-7'>
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" @change="onFileChange10($event)" id="inputGroupFile010"
                        :placeholder="placeholder10" aria-describedby="inputGroupFileAddon010">
                        <label class="custom-file-label split_file_label" for="inputGroupFile010">{{placeholder10}}</label>
                      </div>
                    </div>
                    <div class='col-5'>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">Rp</span>
                        </div>
                        <input type="text" placeholder="0" v-model="bukti_pembayaran_taxi_nominal"  class="form-control" aria-label="Nomonial (Rupiah)">
                      </div>
                    </div>
                  </div>
                </div>  
              </CCol>
              <CCol sm="4">
                <div class="form-group">
                  <label>Total Nominal</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Rp</span>
                    </div>
                    <input type="text" placeholder="0" readonly :value="total"  class="form-control" aria-label="Nominal (Rupiah)">
                  </div>
                </div>
              </CCol>
              <CCol sm="4" v-if="level=='Admin'">
                <div class="form-group">
                <label>Status Review</label>
                  <select v-model="status_review" class="form-control">
                    <option value="0">Belum Disetujui</option>
                    <option value="1">Disetujui</option>
                    <option value="2">Pending</option>
                  </select>
                </div>
              </CCol> 
              <CCol sm="4"  v-if="level=='Admin'">             
                <div class="form-group">
                <label>Akun Sakti</label>
                  <select class="form-control" v-model="nama_upt">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option v-for="r in upts" :value="r.id_upt">{{r.nama_upt}} </option>
                  </select>
                </div>
              </CCol>
              <CCol sm="12" v-if="level=='Admin'">
                <CTextarea
                  v-model="notif"
                  label="Notifikasi"
                  placeholder=""
                />
              </CCol>            
            </CRow>      
              <div class="form-actions float-right">
                <CButton type="submit" color="primary" style='margin-right:15px'>{{ submit }}</CButton>
                <CButton color="secondary" to="/dashboard/pie">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
      :show.sync="modalError"
    >
      <b>Error</b>! Data gagal disimpan.
    </CModal>

    <CModal
      title="Error Input"
      color="success"
      id="modalSuccess"
      :show.sync="modalSuccess"
    >
      <b>Success</b>! Data berhasil disimpan.
    </CModal>

    <CModal title="Warning" :show.sync="myModal" size="sm">
      Maaf! File Dokumen/PDF tidak boleh ada yang kosong...
    </CModal>
    <CModal title="Warning" :show.sync="myModal2" size="sm">
      Berhasil! Anda berhasil upload dokumen...
    </CModal>
  </div>    
  
</template>
<style scoped>
.split_file_label{
  overflow-x: hidden;
}
</style>
<script>

let user = JSON.parse(localStorage.getItem('user'));
// let level = user.level;
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      linkPdf: "https://api-siperjadin.vercel.app/surattugas/cetak/"+this.$route.params.id_surat_tugas,
      submit: "Simpan",
      modalError : false,
      modalSuccess : false,
      myModal: false,
      myModal2: false,
      alert1 : false,
      notif : "",
      no_surat_tugas : "",
      tgl_surat_tugas : "",
      uraian_surat_tugas : "",
      tgl_pelaksanaan : "",
      jumlah_hari_pelaksanaan : 0,
      nama_pelaksana : "",
      dasar_surat_tugas : "",
      pejabat_ttd : "",
      jenis_tujuan : "1",
      file_surat_tugas_text : "",
      file_surat_tugas : "",
      file_perjadin_text : "",
      file_perjadin : "",
      file_laporan_kegiatan_text : "",
      file_laporan_kegiatan : "",
      file_dasar_perjadin_text : "",
      file_dasar_perjadin : "",
      file_bukti_pembayaran_tiket_text : "",
      file_bukti_pembayaran_tiket : "",
      file_bukti_pembayaran_penginapan_text : "",
      file_bukti_pembayaran_penginapan : "",
      file_bukti_pembayaran_sewa_kendaraan_text : "",
      file_bukti_pembayaran_sewa_kendaraan : "",
      file_bukti_pembayaran_bbm_text : "",
      file_bukti_pembayaran_bbm : "",
      file_bukti_pembayaran_transport_lainnya_text : "",
      file_bukti_pembayaran_transport_lainnya : "",
      file_bukti_pembayaran_taxi_text : "",
      file_bukti_pembayaran_taxi : "",
      bukti_pembayaran_tiket_nominal : "0",
      bukti_pembayaran_penginapan_nominal : "0",
      bukti_pembayaran_sewa_kendaraan_nominal : "0",
      bukti_pembayaran_bbm_nominal : "0",
      bukti_pembayaran_transport_lainnya_nominal : "0",
      bukti_pembayaran_taxi_nominal : "0",
      total_nominal : 0,
      placeholder : "Pilih File",
      placeholder2 : "Pilih File",
      placeholder3 : "Pilih File",
      placeholder4 : "Pilih File",
      placeholder5 : "Pilih File",
      placeholder6 : "Pilih File",
      placeholder7 : "Pilih File",
      placeholder8 : "Pilih File",
      placeholder9 : "Pilih File",
      placeholder10 : "Pilih File",
      status_review : "0",
      nama_upt : "",
      selected: [], // Must be an array reference!
      upts: [],
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      level: '',
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  created(){
      let us = JSON.parse(this.$store.state.users);
      this.level = us.level
  },
  mounted(){
  this.loadData();
  if(this.notif){
    alert1=true
  }
  this.showSurattugas();
  },
  computed: {
    total: function() {
      let calculatedTotal = +this.bukti_pembayaran_bbm_nominal + +this.bukti_pembayaran_penginapan_nominal + +this.bukti_pembayaran_sewa_kendaraan_nominal + +this.bukti_pembayaran_taxi_nominal + +this.bukti_pembayaran_tiket_nominal + +this.bukti_pembayaran_transport_lainnya_nominal;
      this.total_nominal = calculatedTotal;
      
      return calculatedTotal;
    }
  },
  methods: {
    
    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"upts")
        .then((response) => {
              this.upts=response.data;
        })
    },

    onFileChange1(event) {
      var fileData = event.target.files[0];
      this.file_surat_tugas_text = fileData.name;
      this.file_surat_tugas = fileData;
      this.placeholder = fileData.name;
    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.file_perjadin_text = fileData.name;
      this.file_perjadin = fileData;
      this.placeholder2 = fileData.name;
    },
    onFileChange3(event) {
      var fileData = event.target.files[0];
      this.file_laporan_kegiatan_text = fileData.name;
      this.file_laporan_kegiatan = fileData;
      this.placeholder3 = fileData.name;
    },
    onFileChange4(event) {
      var fileData = event.target.files[0];
      this.file_dasar_perjadin_text = fileData.name;
      this.file_dasar_perjadin = fileData;
      this.placeholder4 = fileData.name;
    },
    onFileChange5(event) {
      var fileData = event.target.files[0];
      this.file_bukti_pembayaran_tiket_text = fileData.name;
      this.file_bukti_pembayaran_tiket = fileData;
      this.placeholder5 = fileData.name;
    },
    onFileChange6(event) {
      var fileData = event.target.files[0];
      this.file_bukti_pembayaran_penginapan_text = fileData.name;
      this.file_bukti_pembayaran_penginapan = fileData;
      this.placeholder6 = fileData.name;
    },
    onFileChange7(event) {
      var fileData = event.target.files[0];
      this.file_bukti_pembayaran_sewa_kendaraan_text = fileData.name;
      this.file_bukti_pembayaran_sewa_kendaraan = fileData;
      this.placeholder7 = fileData.name;
    },
    onFileChange8(event) {
      var fileData = event.target.files[0];
      this.file_bukti_pembayaran_bbm_text = fileData.name;
      this.file_bukti_pembayaran_bbm = fileData;
      this.placeholder8 = fileData.name;
    },
    onFileChange9(event) {
      var fileData = event.target.files[0];
      this.file_bukti_pembayaran_transport_lainnya_text = fileData.name;
      this.file_bukti_pembayaran_transport_lainnya = fileData;
      this.placeholder9 = fileData.name;
    },
    onFileChange10(event) {
      var fileData = event.target.files[0];
      this.file_bukti_pembayaran_taxi_text = fileData.name;
      this.file_bukti_pembayaran_taxi = fileData;
      this.placeholder10 = fileData.name;
    },

    updateUpload: function(event){

      const formData = new FormData();        
        
        formData.append('no_surat_tugas', this.no_surat_tugas)
        formData.append('tgl_surat_tugas', this.tgl_surat_tugas)
        formData.append('uraian_surat_tugas', this.uraian_surat_tugas)
        formData.append('jumlah_hari_pelaksanaan', this.jumlah_hari_pelaksanaan)
        formData.append('tgl_pelaksanaan', this.tgl_pelaksanaan)
        formData.append('nama_pelaksana', this.nama_pelaksana)
        formData.append('dasar_surat_tugas', this.dasar_surat_tugas)
        formData.append('pejabat_ttd', this.pejabat_ttd)
        formData.append('total_nominal', this.total_nominal)
        formData.append('status_review', this.status_review)
        formData.append('notif', this.notif)
        formData.append('file_dasar_perjadin_text', this.file_dasar_perjadin.name)
        formData.append('bukti_pembayaran_taxi_nominal', this.bukti_pembayaran_taxi_nominal)
        formData.append('bukti_pembayaran_bbm_nominal', this.bukti_pembayaran_bbm_nominal)
        formData.append('bukti_pembayaran_transport_lainnya_nominal', this.bukti_pembayaran_transport_lainnya_nominal)
        formData.append('bukti_pembayaran_sewa_kendaraan_nominal', this.bukti_pembayaran_sewa_kendaraan_nominal)
        formData.append('bukti_pembayaran_penginapan_nominal', this.bukti_pembayaran_penginapan_nominal)
        formData.append('bukti_pembayaran_tiket_nominal', this.bukti_pembayaran_tiket_nominal)

        if(this.nama_upt){
          formData.append('id_upt', this.nama_upt)
        }
        if (this.file_surat_tugas_text) {
          if(this.file_surat_tugas.name){
            formData.append('file_surat_tugas', this.file_surat_tugas, this.file_surat_tugas.name) 
            formData.append('file_surat_tugas_text', this.file_surat_tugas.name) 
          }
        }
        if (this.file_perjadin_text) {
          if(this.file_perjadin.name){  
            formData.append('file_perjadin', this.file_perjadin, this.file_perjadin.name) 
            formData.append('file_perjadin_text', this.file_perjadin.name)
          }
        }
        if (this.file_dasar_perjadin_text) {
          if(this.file_dasar_perjadin.name){
            formData.append('file_dasar_perjadin', this.file_dasar_perjadin, this.file_dasar_perjadin.name) 
          }
        }
        if (this.file_bukti_pembayaran_tiket_text) {
          formData.append('file_bukti_pembayaran_tiket', this.file_bukti_pembayaran_tiket, this.file_bukti_pembayaran_tiket.name) 
          formData.append('file_bukti_pembayaran_tiket_text', this.file_bukti_pembayaran_tiket.name)
        }
        if (this.file_bukti_pembayaran_penginapan_text) {
          formData.append('file_bukti_pembayaran_penginapan', this.file_bukti_pembayaran_penginapan, this.file_bukti_pembayaran_penginapan.name) 
          formData.append('file_bukti_pembayaran_penginapan_text', this.file_bukti_pembayaran_penginapan.name)
        }
        if (this.file_bukti_pembayaran_sewa_kendaraan_text) {
          formData.append('file_bukti_pembayaran_sewa_kendaraan', this.file_bukti_pembayaran_sewa_kendaraan, this.file_bukti_pembayaran_sewa_kendaraan.name) 
          formData.append('file_bukti_pembayaran_sewa_kendaraan_text', this.file_bukti_pembayaran_sewa_kendaraan.name)
        }
        if (this.file_bukti_pembayaran_transport_lainnya_text) {
          formData.append('file_bukti_pembayaran_transport_lainnya', this.file_bukti_pembayaran_transport_lainnya, this.file_bukti_pembayaran_transport_lainnya.name) 
          formData.append('file_bukti_pembayaran_transport_lainnya_text', this.file_bukti_pembayaran_transport_lainnya.name)
        }
        if (this.file_bukti_pembayaran_bbm_text) {
          formData.append('file_bukti_pembayaran_bbm', this.file_bukti_pembayaran_bbm, this.file_bukti_pembayaran_bbm.name) 
          formData.append('file_bukti_pembayaran_bbm_text', this.file_bukti_pembayaran_bbm.name)
        }
        if (this.file_bukti_pembayaran_taxi_text) {
          formData.append('file_bukti_pembayaran_taxi', this.file_bukti_pembayaran_taxi, this.file_bukti_pembayaran_taxi.name) 
          formData.append('file_bukti_pembayaran_taxi_text', this.file_bukti_pembayaran_taxi.name)
        }
        if (this.file_laporan_kegiatan_text) {
          if(this.file_laporan_kegiatan.name){
            formData.append('file_laporan_kegiatan', this.file_laporan_kegiatan, this.file_laporan_kegiatan.name) 
            formData.append('file_laporan_kegiatan_text', this.file_laporan_kegiatan.name)
          }
          
        }
         
      // alert(JSON.stringify(formData));
    if (!this.file_perjadin_text || !this.file_surat_tugas_text || !this.file_dasar_perjadin_text || !this.file_laporan_kegiatan_text) {
     
      this.myModal = true;
    }
    else{
        this.submit= "Loading...";
            
      axios.post(process.env.VUE_APP_BASE_URL+"surattugas/update/"+this.$route.params.id_surat_tugas, formData)
        .then((response) => {
            this.submit="Simpan";
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.modalSuccess = true;
              if(this.jenis_tujuan=='1')
                this.$router.push('/perjadin/laporandalamprovinsi');
              if(this.jenis_tujuan=='2')
                this.$router.push('/perjadin/laporanluarprovinsi');;
              if(this.jenis_tujuan=='3')
                this.$router.push('/perjadin/laporanluarnegeri');
            }
            else{
              this.modalError = true;
            }            
        })
      }
    },
    showSurattugas: function(event){
    
      const surattugas = { 
                          no_surat_tugas: this.no_surat_tugas, 
                          tgl_surat_tugas: this.tgl_surat_tugas, 
                          uraian_surat_tugas: this.uraian_surat_tugas,
                          tgl_pelaksanaan: this.tgl_pelaksanaan,
                          jumlah_hari_pelaksanaan: this.jumlah_hari_pelaksanaan,
                          nama_pelaksana: this.nama_pelaksana,
                          dasar_surat_tugas: this.dasar_surat_tugas,
                          jenis_tujuan: this.jenis_tujuan,
                          notif: this.notif,
                          pejabat_ttd: this.pejabat_ttd
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"surattugas/"+this.$route.params.id_surat_tugas)
        .then((response) => {            
              this.no_surat_tugas=response.data.no_surat_tugas;
              this.tgl_surat_tugas=response.data.tgl_surat_tugas;
              this.uraian_surat_tugas=response.data.uraian_surat_tugas;
              this.tgl_pelaksanaan=response.data.tgl_pelaksanaan;
              this.jumlah_hari_pelaksanaan=response.data.jumlah_hari_pelaksanaan;
              this.dasar_surat_tugas=response.data.dasar_surat_tugas;
              this.nama_pelaksana=response.data.nama_pelaksana;
              this.pejabat_ttd=response.data.pejabat_ttd;
              this.jenis_tujuan=response.data.jenis_tujuan;
              this.status_review=response.data.status_review;
              this.notif=response.data.notif;
              this.alert1=response.data.notif ? true : false; 
              this.total_nominal=response.data.total_nominal;

              this.placeholder=response.data.file_surat_tugas ?? "N/A";
              this.file_surat_tugas_text=response.data.file_surat_tugas ?? "";
              this.placeholder2=response.data.file_perjadin ?? "N/A";
              this.file_perjadin_text=response.data.file_perjadin ?? "";
              this.placeholder3=response.data.file_laporan_kegiatan ?? "N/A";
              this.file_laporan_kegiatan_text=response.data.file_laporan_kegiatan ?? "";
              this.placeholder4=response.data.file_dasar_perjadin ?? "N/A";
              this.file_dasar_perjadin_text=response.data.file_dasar_perjadin ?? "";

              this.placeholder5=response.data.file_bukti_pembayaran_tiket ?? "N/A";
              this.placeholder6=response.data.file_bukti_pembayaran_penginapan ?? "N/A";
              this.placeholder7=response.data.file_bukti_pembayaran_sewa_kendaraan ?? "N/A";
              this.placeholder8=response.data.file_bukti_pembayaran_bbm ?? "N/A";
              this.placeholder9=response.data.file_bukti_pembayaran_transport_lainnya ?? "N/A";
              this.placeholder10=response.data.file_bukti_pembayaran_taxi ?? "N/A";
              this.bukti_pembayaran_bbm_nominal=response.data.bukti_pembayaran_bbm_nominal;
              this.bukti_pembayaran_penginapan_nominal=response.data.bukti_pembayaran_penginapan_nominal;
              this.bukti_pembayaran_sewa_kendaraan_nominal=response.data.bukti_pembayaran_sewa_kendaraan_nominal;
              this.bukti_pembayaran_taxi_nominal=response.data.bukti_pembayaran_taxi_nominal;
              this.bukti_pembayaran_tiket_nominal=response.data.bukti_pembayaran_tiket_nominal;
              this.bukti_pembayaran_transport_lainnya_nominal=response.data.bukti_pembayaran_transport_lainnya_nominal;
        })
    }
  }
}
</script>
